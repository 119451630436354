/**
 * ユーザ情報取得用モジュール
 * @module idis/service/UserInfo
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/cookie',
    'idis/consts/USER_TYPE',
    '../service/Requester',
    'app/config',
    'app/consts/PUB_STATUS',
    'idis/consts/ACL'
], function (module, declare, lang, cookie, USER_TYPE, Requester, config, PUB_STATUS, ACL) {

    /**
     * ユーザ情報を管理するオブジェクト
     */
    return new declare(null, {
        /**
         * ユーザ情報に対するリクエスト結果
         * @type {Promise<Object>}
         */
        _promise: null,

        /**
         * ユーザ情報
         * @type {Object}
         */
        _userInfo: null,

        /**
         * 訓練フラグ
         * 0:実災害モード、 1:訓練モード
         */
        _trainingFlg: null,

        // ログインモード対照オブジェクト
        loginMode: {
            '0': '本番',
            '1': '訓練'
        },

        /**
         * 実行モード
         * LGWAN or INTERNET
         */
        _runningMode: 'INTERNET',


        /**
         * 市町村コード（デフォルト）
         */
        DEFAULT_MUNICIPALITY_CD: config.municInfo.prefMunicCd,

        /**
         * 認可の種類
         */
        AUTHZ_TYPE_READ: '00',
        AUTHZ_TYPE_WRITE: '01',
        AUTHZ_TYPE_PROXY_WRITE: '02',

        // システム管理者の役割コード
        ROLE_CD_SYS_ADMIN: 'R01001',
        // 危機管理者の役割コード
        ROLE_CD_KIKIKANRI_ADMIN: 'R01011',
        ROLE_CD_KIKIKANRI: 'R01012',

        /**
         * ユーザ情報をサーバーから取得し、成否をPromiseとして返す。
         * @returns {Promise}
         */
        load: function () {
            if (!this._promise) {
                this._userInfo = {};
                this._promise = Requester.get('/api/auth/init').then(lang.hitch(this, function (userInfo) {
                    this._userInfo = userInfo;

                    var municipalityCd = this.getMunicipalityCd();
                    if (municipalityCd) {
                        this.setSelectedMunicipalityCd(municipalityCd);
                    } else {
                        this.setSelectedMunicipalityCd(this.DEFAULT_MUNICIPALITY_CD);
                        console.warn('ユーザ情報に対象の市/行政区コードが設定されていないため、デフォルトの市/行政区コードを適用します');
                    }

                    // 訓練フラグは災害名とセットなので、災害IDと合わせてcookieに保持しておく。
                    this.setTrainingFlg(this._userInfo.trainingFlg);

                    // 実行モード
                    this.setRunningMode(this._userInfo.runningMode);

                    if ('disasterMobileId' in userInfo && !!userInfo.disasterMobileId) {
                        this.setDisasterId(parseInt(userInfo.disasterMobileId));
                    }

                    console.debug('デフォルトの市/行政区コード：' + this.getSelectedMunicipalityCd());
                }));
            }
            return this._promise;
        },

        /**
         * 確認対象に対しアクセス件を持っているかどうかを判定する。
         * @param {Object} data 確認対象
         * @param {string} data.pubStatus 公開状態
         * @param {string} data.userId 登録者のユーザID
         * @param {string} data.deptCd 登録者の部コード
         * @param {string} data.pubSectCd 公開先課コード
         */
        hasAccess: function (data) {
            var logPrefix = module.id + '#isAllowed: ';
            var pubStatus = data.pubStatus;
            if (!data || !/^[0-9]$/.test(pubStatus)) {
                // 有効なpubStatusを持っていない場合はfalse
                console.warn(logPrefix + 'pubStatusが無効: ', data);
                return false;
            }
            var org = this.getOrganization();
            switch (pubStatus) {
                case PUB_STATUS.SELF:
                    if (data.userId === this.getId()) {
                        console.log(logPrefix + '一時保存:登録者本人 (access allowed, pubStatus=' + pubStatus + ')');
                        return true;
                    }
                    break;
                case PUB_STATUS.ORGANIZATION:
                    // deptCd, sectCd, unitCdの全てが一致しているユーザは閲覧可能
                    if ((org.deptCd === data.deptCd || !org.deptCd && !data.deptCd) &&
                        (org.sectCd === data.sectCd || !org.sectCd && !data.sectCd) &&
                        (org.unitCd === data.unitCd || !org.unitCd && !data.unitCd)) {
                        console.log(logPrefix + '組織内共有:組織コードが一致 (access allowed, pubStatus=' + pubStatus + ')');
                        return true;
                    }
                    break;
                case PUB_STATUS.MUNICIPALITY:
                    if (data.municipalityCd === config.municInfo.prefMunicCd &&
                        (this.getUserType() === USER_TYPE.PREFECTURE || this.getUserType() === USER_TYPE.REGION)) {
                        // 県庁内管理レイヤの場合、県・振興局ユーザーは閲覧可能
                        console.log(logPrefix + '県庁内管理:県・振興局ユーザー(access allowed, pubStatus=' + pubStatus + ')');
                        return true;
                    } else if (data.municipalityCd === this.getMunicipalityCd()) {
                        // 市町村内管理レイヤの場合、同じ市町村のユーザは閲覧可能
                        console.log(logPrefix + '市町村内管理:市町村コードが一致 (access allowed, pubStatus=' + pubStatus + ')');
                        return true;
                    }
                    break;
                case PUB_STATUS.PREFECTURE:
                    switch (this.getUserType()) {
                        case USER_TYPE.PREFECTURE:
                            console.log(logPrefix + '県報告:県ユーザー (access allowed, pubStatus=' + pubStatus + ')');
                            return true;
                        case USER_TYPE.REGION:
                            if (data.municipalityCd && this.getMunicipalityCds().indexOf(data.municipalityCd) > -1 ||
                                (data.municipalityCd === config.municInfo.cityMunicCd &&
                                    this.getMunicipalityCds().indexOf(config.municInfo.defaultMunicCd) > -1)) {
                                console.log(logPrefix +
                                    '県報告:振興局ユーザー管内市町村報告 (access allowed, pubStatus=' + pubStatus + ')');
                                return true;
                            } else if (!data.municipalityCd) {
                                console.log(logPrefix +
                                    '県報告:その他ユーザー報告 (access allowed, pubStatus=' + pubStatus + ')');
                                return true;
                            }
                            break;
                        case USER_TYPE.MUNICIPALITY:
                            if (data.municipalityCd && data.municipalityCd === this.getMunicipalityCd()) {
                                console.log(logPrefix +
                                    '県報告:報告市町村ユーザー (access allowed, pubStatus=' + pubStatus + ')');
                                return true;
                            }
                            break;
                        case USER_TYPE.OTHER_ORGAN:
                            // その他ユーザーの場合、deptCd, sectCd, unitCdの全てが一致しているユーザは閲覧可能
                            if ((org.deptCd === data.deptCd || !org.deptCd && !data.deptCd) &&
                                (org.sectCd === data.sectCd || !org.sectCd && !data.sectCd) &&
                                (org.unitCd === data.unitCd || !org.unitCd && !data.unitCd)) {
                                console.log(logPrefix +
                                    '県報告:組織コードが一致 (access allowed, pubStatus=' + pubStatus + ')');
                                return true;
                            }
                            break;
                    }
                    break;
                case PUB_STATUS.MANAGEMENT:
                    var role = this.getRoleCd();
                    if (role === ACL.ADMIN_USER ||
                        role === ACL.CITY_CRI_MANAGE_USER ||
                        role === ACL.CITY_CRI_STAFF_USER) {
                        console.log(logPrefix + 'システム管理+管理危機管理対策室 (access allowed, pubStatus=' + pubStatus + ')');
                        return true;
                    }
                    break;
                case PUB_STATUS.ALL:
                    console.log(logPrefix + '全体公開 (access allowed, pubStatus=' + pubStatus + ')');
                    return true;
            }
            console.log(logPrefix + '権限なし (access denied, pubStatus=' + pubStatus + ')');
            return false;
        },

        /**
         * 認証済みかどうかを返す。
         * @returns {boolean} 認証済みかどうか
         */
        isAuthed: function () {
            // ユーザIDを持っていれば認証済みとする
            return !!this.getId();
        },

        /**
         * ユーザの識別子を返す。
         * @returns {string} 識別子
         */
        getId: function () {
            return this._userInfo.userId;
        },

        /**
         * ユーザの名を返す。
         * @returns {string} 識別子
         */
        getName: function () {
            return this._userInfo.userName;
        },

        /**
         * 地域コードを返す。
         * @returns {string} 地域コード
         */
        getRegionCd: function () {
            return this._userInfo.regionCd;
        },

        /**
         * 市区町村コードを返す。
         * @returns {string} 市区町村コード
         */
        getMunicipalityCd: function () {
            return this._userInfo.municipalityCd;
        },

        /**
         * 市区町村名を返す。
         * @returns {string} 市区町村名
         */
        getMunicipalityName: function () {
            return this._userInfo.municipalityName;
        },

        /**
         * 緯度を返す。
         * @returns {string} 緯度
         */
        getLatitude: function () {
            return this._userInfo.latitude;
        },

        /**
         * 経度を返す。
         * @returns {string} 緯度
         */
        getLongitude: function () {
            return this._userInfo.longitude;
        },

        /**
         * ユーザの所属情報を返す。
         * @returns {Object} 所属情報
         */
        getOrganization: function () {
            return {
                deptCd: this._userInfo.deptCd,
                sectCd: this._userInfo.sectCd,
                unitCd: this._userInfo.unitCd
            };
        },

        getLowestOrganizationCd: function () {
            if (this._userInfo.unitCd) {
                return this._userInfo.unitCd;
            } else if (this._userInfo.sectCd) {
                return this._userInfo.sectCd;
            } else if (this._userInfo.deptCd) {
                return this._userInfo.deptCd;
            }
        },

        getOrganizationName: function () {
            if (this._userInfo.unitName) {
                return this._userInfo.unitName;
            } else if (this._userInfo.sectName) {
                return this._userInfo.sectName;
            } else if (this._userInfo.deptName) {
                return this._userInfo.deptName;
            }
            return null;
        },

        getAppendedOrganizationName: function () {
            var name = '';
            if (this._userInfo.deptName) {
                name += this._userInfo.deptName;
            } else if (this._userInfo.sectName) {
                name += '　' + this._userInfo.sectName;
            } else if (this._userInfo.unitName) {
                name += '　' + this._userInfo.unitName;
            }
            return name;
        },

        /**
         * ユーザの所属局情報を返す。
         * @returns {string} ユーザの所属局コード
         */
        getDeptCd: function () {
            return this._userInfo.deptCd;
        },
        /**
         * ユーザのタワー情報を返す。
         * @returns {string} ユーザのタワーコード
         */
        getTowerCd: function () {
            return this._userInfo.towerCd;
        },

        /**
         * ユーザの防災対象市町村情報を返す。
         * TODO: 要不要については検討する
         * @returns {Object} 防災対象市町村情報
         */
        getMunicipalityCds: function () {
            return this._userInfo.municipalityCds;
        },

        /**
         * ユーザの権限情報を返す。
         * @returns {Object<string,boolean>} 権限種別をキー、権限を値として持つ値オブジェクト
         */
        getAcl: function () {

            var chief = !!this._userInfo.chiefAdminFlg;
            var admin = chief || !!this._userInfo.systemAdminFlg;

            return {
                CHIEF_ADMIN: chief,
                ADMIN: admin,
                WRITE: admin || !this._userInfo.readOnlyFlg
            };
        },

        /**
         * 認可を返す。
         * @param {string} funcCd 機能コード
         * @returns {boolean} 認可があるかどうか
         */
        hasAuthz: function (funcCd) {
            return this._userInfo.authzs && !!this._userInfo.authzs[funcCd];
        },

        /**
         * 「編集権限」または「代理編集権限」についての認可を返す。
         * @param {string} funcCd 機能コード
         * @returns {boolean} 認可があるかどうか
         */
        hasWriteAuthz: function (funcCd) {
            return this._userInfo.authzs && !!this._userInfo.authzs[funcCd] &&
                (this._userInfo.authzs[funcCd] === this.AUTHZ_TYPE_WRITE ||
                    this._userInfo.authzs[funcCd] === this.AUTHZ_TYPE_PROXY_WRITE);
        },

        /**
         * 役割コードを返す。
         * @returns {string} 役割コード
         */
        getRoleCd: function () {
            return this._userInfo.roleCd;
        },

        /**
         * 役割コードを返す。
         * @returns {string} 役割コード
         */
        isSysAdmin: function () {
            return (this._userInfo.roleCd &&
                this._userInfo.roleCd === this.ROLE_CD_SYS_ADMIN) ? true : false;
        },

        /**
         * 危機管理対策室ユーザかを判定する。(施設種別管理・タイムラインの作成権限に使用)
         * @returns {string} 役割コード
         */
        isKikikanriAdmin: function () {
            return (this._userInfo.roleCd &&
                (this._userInfo.roleCd === this.ROLE_CD_KIKIKANRI_ADMIN ||
                    this._userInfo.roleCd === this.ROLE_CD_KIKIKANRI)) ? true : false;
        },

        /**
         * 管理者フラグを返す。
         * @returns {string} ユーザー種別コード
         */
        getAdminFlg: function () {
            return this._userInfo.adminFlg === '1' ? true : false;
        },

        /**
         * ユーザー種別を返す。
         * @returns {string} ユーザー種別コード
         */
        getUserType: function () {
            return this._userInfo.userType;
        },
        /**
         * ユーザー種別を返す。
         * @returns {string} ユーザー種別コード
         */
        getDamageViewType: function () {
            return this._userInfo.damageViewType;
        },
        /**
         * 人的被害参照種別を返す。
         * @returns {string} 人的被害参照種別コード
        */
        getHumanDamageView: function () {
            return this._userInfo.humanDamageView;
        },
        /**
         * 承認種別を返す。
         * @returns {string} 承認種別コード
         */
        getApprovalType: function () {
            return this._userInfo.approvalType;
        },
        /**
         * 対応中の市町村コードを設定する。
         */
        setSelectedMunicipalityCd: function (municipalityCd) {
            this._userInfo.selectedMunicipalityCd = municipalityCd;

            console.debug('対応中の市/行政区コード：' + this._userInfo.selectedMunicipalityCd);
        },

        /**
         * 市町村コードを返す。
         * @returns {string} 市町村コード
         */
        getSelectedMunicipalityCd: function () {
            return this._userInfo.selectedMunicipalityCd;
        },

        /**
         * 対応中の地域コードを設定する。
         */
        setSelectedRegionCd: function (regionCd) {
            this._userInfo.selectedRegionCd = regionCd;

            console.debug('対応中の地域コード：' + this._userInfo.selectedRegionCd);
        },

        /**
         * 地域コードを返す。
         * @returns {string} 地域コード
         */
        getSelectedRegionCd: function () {
            return this._userInfo.selectedRegionCd;
        },
        /**
         * 物資要請ユーザーIDを返す。
         * @returns {string} 物資要請ユーザーID
         */
        getGovUserId: function () {
            return this._userInfo.govUserId;
        },
        /**
         * 物資要請パスワードを返す。
         * @returns {string} 物資要請パスワード
         */
        getGovPassword: function () {
            return this._userInfo.govPassword;
        },
        /**
         * ユーザの訓練フラグを返す。
         * @returns {string} 識別子
         */
        isTrainingFlg: function () {
            return this._userInfo.trainingFlg;
        },

        /**
        * 訓練フラグをCookieから取得する。
        */
        getTrainingFlg: function () {
            this._trainingFlg = cookie('TRAINING_FLG');
            return this._trainingFlg;
        },

        /**
         * 訓練フラグをCookieにセットする
         * @returns {string} 識別子
         */
        setTrainingFlg: function (flg) {
            this._trainingFlg = flg;
            cookie('TRAINING_FLG', this._trainingFlg);
            cookie('KUNRENPORTAL', 'available', { domain: process.env.KUNREN_PORTAL_WEB });
        },

        /**
         * ログインモードを取得する。
         */
        getLoginMode: function () {
            switch (cookie('TRAINING_FLG')) {
                case 'false': return '本番';
                case 'true': return '訓練';
                default: return '';
            }
        },

        /**
         * 実行モードをCookieから取得する。
         */
        getRunningMode: function () {
            this._runningMode = cookie('RUNNING_MODE');
            return this._runningMode;
        },

        /**
         * 実行モードをCookieにセットする
         * @returns {string} 識別子
         */
        setRunningMode: function (mode) {
            this._runningMode = mode;
            cookie('RUNNING_MODE', this._runningMode);
        },

        setDisasterId: function (disasterId) {
            console.log(disasterId);
            cookie('DISASTER_ID', disasterId);
        },
        /**
         * 動員種別を返す。
         * @returns {string} 動員種別コード
        */
        getMobilizationType: function () {
            return this._userInfo.mobilizationType;
        }
    })();
});
