/* 開発環境用設定ファイル */
define({
    /**
     * geocode関連
     */
    geocode: {
        // geocodeサービスのURL
        url: process.env.GEOCODE_SERVICE_URL,
        arcgisUrl: process.env.GEOCODE_SERVICE_ARCGIS_URL,
        localUrl: process.env.GEOCODE_SERVICE_LOCAL_URL,
        googleUrl: process.env.GEOCODE_SERVICE_GOOGLE_URL,
    },

    map: {
        /**
         * 使用する背景地図一覧（各背景地図情報はapp/map/baselayer/BaseLayerOptionsで定義）
         * 指定されない場合は全選択肢を表示する。
         * @type {identifier[]}
         */
        // baseLayers: ['GSI_STANDARD', 'GSI_ORT'],
        url: process.env.MAP_URL,
        latitude: 34.6937366,
        longitude: 135.4999782
    },

    municInfo: {
        prefCd: '270000', // 都道府県のコード
        prePrefCd: '27', // prefCdの上位2桁
        prefMunicCd: '270008', // 都道府県の自治体コード
        prefName: '大阪府', // 都道府県名
        defaultMunicCd: '271004', // 指定がない時に表示される自治体, 政令指定都市なら区
        parentCdsForSpecialCities: ['271004'], // prefが持つ政令指定都市の自治体コード
        cityMunicCd: '271004', // 操作主体となる自治体コード
        cityMunicName: '大阪市', // 操作主体となる自治体名
        wardList: [
            // 政令指定都市内の区
            '271021',
            '271039',
            '271047',
            '271063',
            '271071',
            '271080',
            '271098',
            '271110',
            '271136',
            '271144',
            '271152',
            '271161',
            '271179',
            '271187',
            '271195',
            '271209',
            '271217',
            '271225',
            '271233',
            '271241',
            '271250',
            '271268',
            '271276',
            '271284',
            '271004'
        ],
        prefRegCdGudge: '027', //自治体コード上3桁から、市町村ではなく、県/振興局であることを判断するコード
        kisyoAreaCd: '2710000', //操作主体の気象エリアコード。気象ページの地区セレクターの制御に利用。
        cityMunicDistrictCd: '100000', //地区コード。地区セレクターの制御に利用。
        cities4multiDistShare: [] // 避難情報で、複数地区で同じポリゴンを多重に共有している市町村（5桁版コード）
    },

    layerPubStatus: {
        roleList: ['R01001', 'R01011', 'R01012', 'R01912', 'R03012']
    },

    dept: {
        soumuDeptCd: 'D02000',
        fireDepartmentDeptCd: 'D46000',
        crisisManagementDeptCd: 'D50000',
        humanResourceDeptCd: 'D61000'
    },

    print: {
        /**
         * 印刷サービスでArcGISサーバーからの画像取得先
         */
        getImageUrl: function () {
            // 内部向けWebサーバー
            return 'http://' + location.host;
        }
    },

    /**
     * Esri地図モジュール用の設定（モバイルで利用）
     */
    esri: {
        initExtent: [
            {
                ymin: 0.562077, // latitude - 0.5
                ymax: 0.562077, // latitude + 0.5
                xmin: 0.654397, // longitude - 0.7
                xmax: 0.054397 // longitude + 0.7
            }
        ],
        bookmarks: [
            {
                name: '庁舎',
                ymin: 0.062077,
                ymax: 0.062077,
                xmin: 0.354397,
                xmax: 0.354397
            }
        ]
    },
    /**
     * 防災ポータル情報
     */
    portalInfo: {
        url: "https://sys2pub.osaka-bousai.z-hosts.com",
        urlTraining: "https://sys2kunrenpub.osaka-bousai.z-hosts.com"
    },

    /**
     * サーバ情報
     */
    server: {
        adisWeb: process.env.ADISWEB_PATH,
        adisTraining: process.env.ADISTRAINING_PATH
    },

    // ファイル共有サイト（外部サイト）のURL
    fileShare: {
        url: '/fileshare'
    },

    // ヘルプデスクの連絡先
    helpdesk: {
        mail: 'bousai-helpdesk@dcloud.z-hosts.com',
        tel: '050-3196-1912'
    }
});
